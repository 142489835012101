// Google Fonts Sen
* {
  font-family: 'Sen', sans-serif;
}

body {
  background-color: #F0F0F0;
}

input:focus {
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-content,
.app-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

bs-datepicker-container, bs-daterangepicker-container {z-index: 9999999999;}

.modal {
  background-color: #00000080;
  z-index: 9999;
}

.app-search {
  position: relative;
  margin-left: 10px;
}

.app-search .form-control {
  border: 1px solid #9292927c;
  font-size: 13px;
  letter-spacing: .03em;
  height: 30px;
  color: #58595b;
  padding: 7px 40px 7px 20px;
  background: rgba(255, 255, 255, .05);
  box-shadow: none;
  border-radius: 30px;

}

.app-search a {
  position: absolute;
  top: 3px;
  right: 14px;
  font-size: 16px;
  color: #F16376;
}

.app-search typeahead-container.dropdown.open.dropdown-menu {
  width: 260px !important;
  overflow-x: hidden !important;
}

span.badge.badge-custom {
  min-width: 70px;
}

div.table-scroll-70,
div.table-scroll-45 {
  height: auto !important;
  overflow-x: hidden;
  overflow-y: auto;
}

div.table-scroll-70 {
  max-height: 70vh !important;
}

div.table-scroll-45 {
  max-height: 45vh !important;
}

div.table-scroll-40 {
  max-height: 40vh !important;
}

.modal-content {
  border: none !important;
}

.modal-dialog.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: none;

  .modal-content {
    height: 100%;
    min-height: 100%;
    border-radius: 0 !important;
    border: none !important;
  }

  .modal-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    font-weight: 300;
    overflow: auto;
  }

  .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    padding: 10px;
    background: #f5f7f9;
  }
}

.modal-centered {
  display: block !important;
  position: fixed !important;
  top: 35% !important;
  right: 0 !important;
  left: 0 !important;
  box-shadow: 0.1rem 0.2rem 0.3rem 0.1rem #888888;
  border-radius: 0.4rem;
}

.modal-content {
  -webkit-border-radius: .3rem !important;
  -moz-border-radius: .3rem !important;
  border-radius: .3rem !important;
}

div.modal-h70>div.modal-content div.modal-body {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}

div.modal-h80>div.modal-content div.modal-body {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.loading-opacity {
  opacity: 0.05 !important;
}

div.card.card-h70>div.card-body {
  height: auto !important;
  max-height: 80vh !important;
  overflow: auto !important;
}

// form-control-label
label.form-control-label {
  font-weight: 600;
}

tr.app-line-click {
  cursor: pointer !important;
}

.form-control[readonly] {
  background-color: #f5f7f9 !important;
}


// CARD STYLES
.card {
  border-radius: 0.7rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border: none;
}


// card actions
.card-header .card-actions {
  position: absolute;
  top: 0;
  right: 0;
}

.card-header .card-actions a,
.card-header .card-actions button {
  display: block;
  float: left;
  width: 50px;
  padding: .75rem 0;
  margin: 0 !important;
  color: #151b1e;
  text-align: center;
  background: transparent;
  border: 0;
  border-radius: 0;
  border-left: 1px solid #c2cfd6;
  box-shadow: 0;
}

p {
  letter-spacing: 1px;
}

h1.title,
h2.title,
h3.title,
h4.title,
h5.title,
h6.title {
  color: #5a7391;
}

h4 {
  font-size: 1.0125rem;
}

/* Block UI Wrapper (Backdrop) */
div.block-ui-wrapper {
  background-color: rgba(138, 135, 135, 0.164);
}

/* Spinner */
div.block-ui-wrapper .loader {
  border-color: #fff;
  /* Circle color */
  border-left-color: #708ada;
  /* Spinning section in circle color */
}

/* Message */
div.block-ui-spinner .message {
  color: #708ada;
  /* Loading text color */
}

.panel.customClass,
.card.customClass {
  margin-bottom: 0.9rem !important;
}

.panel.customClass .card-header,
.card.customClass .card-header {
  padding: 0.275rem 1.25rem !important;
  background-color: #d1dbe1 !important;
  border-bottom: 0 !important;
}

.card.customClass .card-header div.panel-title div.accordion-toggle button {
  border: none;
  color: #333;
  font-weight: 700;
}

.popover.popover-add {
  .popover-title {
    font-weight: 600 !important;
    color: #d46475 !important;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.table-recibo {
  table-layout: fixed !important;
  width: 100% !important;
}

div.card-profile {

  // profile
  .profile-sidebar-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 0.8rem 0 2.7rem 0 !important;

    img.avatar.img-reponsive {
      min-width: 180px;
      min-height: 180px;
      max-width: 200px;
      max-height: 200px;
    }

    button.btn.btn-brand {
      margin-top: -21px !important;
      z-index: 1000;
      opacity: 0.8;
      border-radius: 50% !important;

      i {
        background: none !important;
      }
    }

    .profile-user-title,
    .profile-user-buttons {
      display: block;
      width: 100%;
      color: #5a7391;
    }
  }

  // sidebar menu
  ul.nav>li.nav-item {
    border-bottom: 1px solid #f0f4f7;
  }

  ul.nav>li.nav-item:last-child {
    border-bottom: none;
  }

  ul.nav>li.nav-item>a.nav-link {
    padding: 10px 15px;
    color: #8493a5;
    font-size: 16px;
    font-weight: 400;

    i {
      margin-right: 8px;
      font-size: 16px;
    }
  }

  ul.nav>li.nav-item>a.nav-link:hover {
    background-color: #fafcfd;
    color: #5b9bd1;
  }

  ul.nav>li.nav-item>a.nav-link.active {
    color: #5b9bd1;
    background-color: #f6f9fb;
    border-left: 2px solid #5b9bd1;
    margin-left: -1px;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

}

// tabs
.tab-user-managment {

  .nav-link.active,
  .show>.nav-link {
    color: #fff !important;
    background-color: #d46475 !important;
  }
}

.separator-dashed {
  border-top: 1px dashed #ebedf2;
  margin: 20px 0 !important;
}

@media (max-width: 1366px) {
  .modal-lg {
    min-width: 80% !important;
    max-width: 86% !important;
  }

}

@media (max-width: 992px) {
  .modal-lg {
    min-width: 88% !important;
    max-width: 96% !important;
  }

  .app-search .form-control {
    color: #fff;
  }

}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 370px;
  }
}

@media (max-width: 575px) {


  /*
    ###### container ####
    */
  main.main>div.container-fluid {
    padding: 2px 7px !important;
  }

  div.card-body,
  div.card-block {
    padding: 0.8rem !important;
  }

  .modal-content {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}

@include media-breakpoint-up(lg) {
  .modal-lg {
    min-width: 80% !important;
    max-width: 86% !important;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0 0 3cm !important;
  }

  body {
    margin-top: 20px !important;
  }

  .card,
  .panel {
    border: none !important;
  }
}

.sidebar .nav-link.active .nav-icon {
  color: $app-primary;
}

.sidebar .nav-link:hover .nav-icon {
  color: #E34A62;
}

#toast-container>div {
  opacity: 1;
}

// criando custom icons

.custom-icon {
  padding: 15px;
  border-radius: 99999px;

  svg {
    width: 25px;
    height: 25px;
    stroke-width: 1.5px;
  }

  &.icon-pink {
    background-color: #fdeaec;

    svg {
      stroke: #F16376;
    }
  }

  &.icon-orange {
    background-color: #FEEDDC;

    svg {
      stroke: #F48307;
    }
  }

  &.icon-green {
    background-color: #E8F3E9;

    svg {
      stroke: #5AAA64;
    }
  }

  &.icon-purple {
    background-color: #ECEAF3;

    svg {
      stroke: #60519A;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar {
  .nav-title {
    text-transform: capitalize;
    color: #4F4043;
    font-size: 14px;
    font-weight: normal;
  }

  .nav-link {
    color: #4F4043;

    &:hover {
      color: #E34A62 !important;
      background: #FDE9EC !important;
      border-radius: 15px 0 0 15px;

    }
  }

  .nav-link.active {
    background: #FDE9EC;
    color: #E34A62;
    font-weight: 600;
    border-radius: 15px 0 0 15px;
    border-right: 5px solid #E34A62;
  }

  .nav-dropdown-items {
    margin-left: 15px;
  }

  .sidebar-header {
    background: none;
  }

  .nav-dropdown.open {
    background: none;
  }

  .nav-dropdown.open .nav-link {
    color: #4F4043;

    &.nav-link.active {
      color: #E34A62;
    }
  }
}

.app-header {
  border: none;
}

// Aumentando altura app-header
@media (min-width: 992px) {
  .header-fixed .app-header {
    height: 70px;
  }
}


// Aumentando largura menu lateral
@media (min-width: 992px) {
  .sidebar-fixed .app-header+.app-body .sidebar {
    width: 250px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  }

  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main,
  html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 250px;
  }
}

html:not([dir=rtl]) .sidebar {
  margin-left: -250px;
}

.sidebar .sidebar-nav {
  padding-left: 20px;
  width: 100%
}

.sidebar .nav {
  width: 100%
}

.info-usuario {
  margin-left: 15px;
  text-align: left;
}

.foto-avatar {
  display: flex;
  align-items: center;
}

.sidebar-nome-usuario {
  font-size: 18px;
  color: #4F4043;
}

.sidebar-profissional-title {
  font-size: 16px;
  color: #F16376;
  line-height: initial;
}

.sidebar-nome-empresa {
  font-size: 16px;
  color: #4F4043;
  line-height: initial;
}
