// overide
$theme-colors: (
    "app-primary": #d46475,
    "app-secondary": #58595b,
    "app-light": #f5f7f9,
    "app-grey": rgb(143, 155, 166)
);

$app-primary: #D46475 !default;
$app-secondary: #58595B !default;

$navbar-height: 55px !default;

$navbar-brand-width: 200px !default;

$navbar-brand-bg: #FFFFFF !default;
$sidebar-bg: #FFF !default;
$sidebar-color: #4F4043;

$tour-orb-color: $app-primary;
$tour-next-button-color: #d46475;
$tour-next-button-hover: #d46475;
$tour-next-text-color: #4F4043;
$tour-back-button-color: #d46475;
