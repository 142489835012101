/* You can add global styles to this file, and also import other style files */
@import "variables";


// Import styles
@import "~@coreui/coreui/scss/coreui";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import '~ngx-toastr/toastr-bs4-alert';

@import '~ngx-guided-tour/scss/guided-tour-base-theme.scss';

@import '~angular-archwizard/archwizard.scss';

// If you want to add something do it here
@import "custom";


